import React from 'react';
import "./Button.css";

function Button({onclickHandler, label, style }) {
  return (
    <button
      className="button" 
      onClick={onclickHandler}
      style={style}
    >
        {label}
    </button>
  );
}

export default Button;
