import React from 'react';
import './PricingCard.css';

export const PricingCard = ({ title, price, duration, numPhotos, consultation }) => {
  return (
    <div className="pricing-card">
      <h3>{title}</h3>
      <h3>${price}</h3>
      <div className="grid-container">
        <span>Duration</span>
        <span>{duration}</span>
        <span className='lineFull'></span>
        <span>Number of Photo</span>
        <span>{numPhotos}</span>
        <span className='lineFull'></span>
        <span>Consultation on style</span>
        <span className='lineFull'></span>
      </div>
    </div>
  );
}

export const LearningCard = ({ title, price, duration }) => {
    return (
      <div className="pricing-card">
        <h3>{title}</h3>
        <h3>${price}</h3>
      <div className="grid-container">
        <span>Duration</span>
        <span>{duration}</span>
        <span className='lineFull'></span>
        <span>Any camera model</span>
        <span className='lineFull'></span>
        <span>Beginners/Intermediate</span>
        <span className='lineFull'></span>
      </div>
      </div>
    );
  }
  