import React from 'react';
import "./Dialog.css";
import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

function Dialog({ isOpen, onClose, title, message, loading }) {
    if (!isOpen) return null;
    return (
        <dialog className="dialog-overlay">
            <div className="dialog">
                {loading ? <LoadingSpinner /> : <div className='dialog-content'>
                    <h3>{title}</h3>
                    <p>{message}</p>
                    <Button label={"Close"} onclickHandler={onClose} />
                </div>}
            </div>
        </dialog>
    );
}
export default Dialog;
