import React, { useEffect, useRef, useState } from 'react';
import HomeSection from './HomeSection';
import PortfolioSection from './PortfolioSection';
import AboutSection from './AboutSection';
import ContactSection from './ContactSection';
import Heading from './Heading';
import Footer from './Footer';
import { trackEvent } from './mixpanel';
import PricingSection from './PricingSection';
import HamburgerButton from './HamburgerButton';
import SidebarMenu from './SidebarMenu';
import "./App.css";

function App() {
  useEffect(() => {
    trackEvent("User visited page", {});
  }, [])
  const homeInputRef = useRef(null);
  const portfolioInputRef = useRef(null);
  const pricingInputRef = useRef(null);
  const contactInputRef = useRef(null);

  const [isMenuActive, setIsMenuActive] = useState(false);

  const toggleMenu = () => {
    trackEvent("Hamburger menu clicked", {})
    setIsMenuActive(!isMenuActive);
  };
  const focusHomeSection = () => {
    toggleMenu();
    homeInputRef.current.scrollIntoView({ behavior: 'smooth' });
    homeInputRef.current.focus();
    trackEvent("focusHomeSection", {})
  };
  const focusPortfolioSection = () => {
    toggleMenu();
    portfolioInputRef.current.scrollIntoView({ behavior: 'smooth' });
    portfolioInputRef.current.focus();
    trackEvent("focusPortfolioSection", {})
  };
  const focusPricingSection = () => {
    toggleMenu();
    pricingInputRef.current.scrollIntoView({ behavior: 'smooth' });
    pricingInputRef.current.focus();
    trackEvent("focusPricingSection", {})
  };
  const focusContactSection = () => {
    toggleMenu();
    contactInputRef.current.scrollIntoView({ behavior: 'smooth' });
    contactInputRef.current.focus();
    trackEvent("focusContactSection", {})
  };
  const focusContactSectionWithoutToggle = () => {
    contactInputRef.current.scrollIntoView({ behavior: 'smooth' });
    contactInputRef.current.focus();
    trackEvent("focusContactSectionWithoutToggle", {})
  };
  return (
    <div className="App">
      {/* <Header /> */}
      <div style={{ position: 'fixed', margin: "16px", top: 0, right: 0, zIndex: 100 }}>
        <HamburgerButton isActive={isMenuActive} toggleMenu={toggleMenu} />
      </div>
      <div className="icon-container" style={{ position: 'fixed', margin: "16px", top: 0, left: 0, zIndex: 100 }}>
      <a href="https://www.instagram.com/ajai_maxwel/" class="instagram-icon" target="_blank" aria-label="Instagram">
  <i class="fa-brands fa-instagram"></i>
</a>
      </div>
      {isMenuActive && <SidebarMenu isActive={isMenuActive}
        scrollToHome={focusHomeSection}
        scrollToPortfolio={focusPortfolioSection}
        scrollTopricing={focusPricingSection}
        scrollToContact={focusContactSection}
      />}
      <HomeSection scrollToContact={focusContactSectionWithoutToggle} inputRef={homeInputRef} />
      <Heading title="Porfolio" />
      <PortfolioSection inputRef={portfolioInputRef} />
      <Heading title="Pricing" />
      <PricingSection inputRef={pricingInputRef} />
      <Heading title="Contact Me" />
      <ContactSection inputRef={contactInputRef} />
      <Footer />
    </div>
  );
}

export default App;
// https://www.behance.net/gallery/132313031/Landing-page-for-photographer?tracking_source=search_projects_views|Photographer+Portfolio
