import React from 'react';

function Footer() {
    return (
        <footer style={{ margin: "24px", fontWeight: 100}}>
            <p>&copy; 2023 Ajai Maxwell. All rights reserved.</p>
        </footer>
    );
}

export default Footer;
