import React, {useEffect, useState} from 'react';
import "./PortfolioSection.css";
import Button from "./Button";
// D:\Code\photographer-portfolio\src\resources\portfolio\img1.jpg
import { trackEvent } from './mixpanel';

function PortfolioSection({inputRef}) {
    const [images, setImages] = useState([]);
    const [shown, setShown] = useState(4);
    const [showAll, setShowAll] = useState(false);
  
    useEffect(() => {
      const loadedImages = [];
      for (let i = 1; i <= 38; i++) {
        loadedImages.push(`portfolio/img${i}.jpg`);
      }
      setImages(loadedImages);
    }, []);
  
    const handleToggleShow = () => {
      trackEvent("Show more clicked", {"shown": shown})
      if (showAll) {
        setShown(4);
        setShowAll(!showAll);
      } else if (shown + 4 >= 38) {
        setShowAll(!showAll);
      } else {
        setShown(shown + 4);
      }
    };
  
    return (
      <div  ref={inputRef}>
        <div className="portfolio-grid">
          {images.slice(0, showAll ? images.length : shown).map((src, index) => (
            <div key={index} className="portfolio-item">
              <img src={src} alt={`Portfolio ${index + 1}`} />
            </div>
          ))}
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <Button 
              label={showAll ? 'Collapse' : 'Show More'}
              onclickHandler={handleToggleShow}
            />
        </div>

      </div>
    );
}

export default PortfolioSection;
