import React from 'react';
import "./Heading.css";

function Heading({ title }) {
    return (
        <div>
            <h1>
                {title}
            </h1>
        </div>
    );
}

export default Heading;
