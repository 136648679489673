import React from 'react';
import "./HomeSection.css";
import Button from './Button';

function HomeSection({scrollToContact, inputRef }) {
  return (
    <div className="container" ref={inputRef}>
      <div className="right">
        <img src="/portfolio/flying_seagull.jpg" alt="Camera picture" />
      </div>

      <div className="content">
          <h2 className="title" >the Maxwell</h2>
          <h2 className="title">Photography</h2>
          <Button 
            label="Schedule Session"
            onclickHandler={scrollToContact}
            style={{margin: "1rem"}}
          />
      </div>
    </div>
  );
}

export default HomeSection;
