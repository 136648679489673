import React from "react";
import "./HamburgerButton.css";

function HamburgerButton({ isActive, toggleMenu }) {
  return (
    <div
      className={`hamburger ${isActive ? "active" : ""}`}
      onClick={toggleMenu}
    >
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </div>
  );
}

export default HamburgerButton;