import React from "react";
import "./SidebarMenu.css";

function SidebarMenu({ isActive,scrollToHome, scrollToPortfolio, scrollTopricing, scrollToContact }) {
  return (
    <div className={`sidebar-menu ${isActive ? "active" : ""}`}>
      <ul>
        <li onClick={scrollToHome}>Home</li>
        <li onClick={scrollToPortfolio}>Portfolio</li>
        <li onClick={scrollTopricing}>Pricing</li>
        <li onClick={scrollToContact}>Contact</li>
      </ul>
    </div>
  );
}

export default SidebarMenu;