import React from 'react';
import { PricingCard, LearningCard } from './PricingCard';

import "./PricingSection.css";

function PricingSection({inputRef}) {
  return (
    <div ref={inputRef}>
      <div className="pricing-cards">
        <PricingCard title="Photoshoot" price="100" duration="1 hour" numPhotos="20-40" consultation="Yes" />
        <PricingCard title="Photoshoot" price="200" duration="2 hours" numPhotos="50-70" consultation="Yes" />
        <PricingCard title="Photoshoot" price="250" duration="2 hours" numPhotos="all" consultation="Yes" />
        <LearningCard title="1-on-1 Coaching" price="50" duration="1 hours" />
      </div>
      {/* <button className="btn-schedule">SCHEDULE PHOTOSESSION</button> */}
    </div>
  );
}

export default PricingSection;