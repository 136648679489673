import React from 'react';
import "./LoadingSpinner.css";

function LoadingSpinner() {
    return (
        <div style={{ display: "flex", flexDirection: "column"}}>
            <div id="wrapper">
                <div id="corpus"></div>
                <div id="spinner"></div>
            </div>
            <div id="loadingMessage">Requesting ...</div>
        </div>

    );
}

export default LoadingSpinner;
