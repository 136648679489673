import React, { useRef, useState } from 'react';
import "./ContactSection.css";
import { trackEvent } from './mixpanel';
import Dialog from './Dialog';
import Button from './Button';

function ContactSection({ inputRef }) {
    const dateTimeRef = useRef();
    const formRef = useRef();
    const [dialog, setDialog] = useState({ isOpen: false, title: '', message: '', loading: false });

    const handleCloseDialog = () => {
        setDialog({ isOpen: false, title: '', message: '' });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        dateTimeRef.current.value = new Date().toISOString();
        const formData = new FormData(event.target);
        const filledData = {
            name: formData.get('name'),
            email: formData.get('email'),
            message: formData.get('message')
        }
        console.log("Form data ", filledData);
        trackEvent('Photo Request submitted', filledData);
        setDialog({
            loading: true,
            isOpen: true,
            // title: 'Thank you!',
            // message: 'Your request has been successfully submitted, we shall contact you within 2 days'
        });

        fetch('https://script.google.com/macros/s/AKfycbyRqgFwD1WDfSsi9ucz2IhcyKqBsBPD0uNWQIg0E9t-6bVxJguCZKgrkgSwZwUPuOrU/exec', {
            method: 'POST',
            mode: 'no-cors', // Google Apps Script Web Apps do not support CORS
            body: formData,
        })
            .then((res) => {
                console.log('Form submitted successfully!', res);
                formRef.current.reset(); // Reset the form
                setDialog({
                    isOpen: true,
                    loading: false,
                    title: 'Thank you!',
                    message: 'Your request has been successfully submitted, we shall contact you within 2 days'
                });
            })
            .catch((error) => {
                console.error('Error:', "There was an issue send this request, please email to maxwel.ajai@gmail.com");

                setDialog({
                    isOpen: true,
                    loading: false,
                    title: 'Failed',
                    message: 'An Error occurred, please email maxwel.ajai@gmail.com'
                });
            });
    };

    return (
        <section className="contact-section">
            <form onSubmit={handleSubmit} ref={formRef}>
                <fieldset >
                    <div className='inputBox'>
                        <label for="text" >Text</label>
                        <input ref={inputRef} type="text" id="text" name="name" required />
                    </div>
                    <div className='inputBox'>
                        <label for="email">email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className='inputBox'>
                        <label for="textarea">message</label>
                        <textarea type="textarea" id="textarea" name="message" />
                    </div>
                    <input type="hidden" name="dateTime" ref={dateTimeRef} />
                    {/* <button type="submit" >Submit</button> */}
                    <div style={{ margin: "16px"}}>
                        <Button
                            type="submit"
                            label={"Submit"}
                            style={{margin: "1rem"}}
                        />
                    </div>
                </fieldset>
            </form>
            <Dialog
                isOpen={dialog.isOpen}
                loading={dialog.loading}
                onClose={handleCloseDialog}
                title={dialog.title}
                message={dialog.message}
            />
        </section>
    );
}

export default ContactSection;
